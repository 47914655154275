export function dropdown() {
  // 属性に`data-trigger-status`を持っている要素をクリックすると下にある要素が開閉する
  const triggerStatus = document.querySelectorAll('[data-trigger-status]');
  triggerStatus.forEach((element) => {
    element.addEventListener('click', () => {
      // 'triggerStatus'の`data-trigger-status`を`opened`と`closed`で切り替える
      const elementDataset = element.dataset;
      const status = elementDataset.triggerStatus;
      if (status === 'opened') {
        elementDataset.triggerStatus = 'closed';
      } else {
        elementDataset.triggerStatus = 'opened';
      }
    });
  });
}
